import React from "react"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import { Card, CardBody, CardTitle } from 'reactstrap';

const PrivacyPolicy = () => {

    return (
        <Layout pageTitle="Privacy Policy">
            <div className="container" id="content">
                <SEO title="Privacy Policy" />
                <Card>
                    <CardTitle className="text-center">
                        <h2>Privacy Policy</h2>
                    </CardTitle>
                    <CardBody>

                        <h3>Personal Information Sharing</h3>
                        <p>
                            This Privacy Policy applies to visitors to our site <a rel="noopener noreferrer"href="https://aneesh.co.uk/">https://aneesh.co.uk/</a>.
                        </p>
                        <h4>MailChimp</h4>
                        <p>
                            This website has an email list which allows us to communicate with the regular readers of this blog. The email marketing tool MailChimp is used for this purpose.
                        <br></br>
                            &#8226; MailChimp collects the following data:<br></br>
                            &#8226; The email address of the subscriber.<br></br>
                            &#8226; The name of the subscriber<br></br>
                            &#8226; The location of the subscriber.<br></br>
                            &#8226; The method that was used to subscribe the newsletter.<br></br>
                            &#8226; The preferred language.<br></br>
                            &#8226; The favorite email client and format.<br></br>
                            &#8226; The last profile update timestamp.<br></br>
                            &#8226; The tags which are used to segment subscribers to different lists.
                            </p>
                        <p>
                            MailChimp collects its data by using these data sources:
                            The email address is provided by the subscriber. Also, when a subscriber opens our email or clicks a link found from our email, MailChimp updates the open and click statistics of the email in question.
                            A list of emails sent to the student is updated by MailChimp when we send a new email to the subscribers of our newsletter.
                            You can find more information about MailChimp <a rel="noopener noreferrer" href="https://mailchimp.com/legal/privacy/" target="_blank">here</a>.
                        </p>
                        <h4>Google Analytics</h4>
                        <p>
                            Our website uses Google Analytics, a web analysis service of Google, Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA,
                            www.google.com (“Google Analytics” or “Google”). Google Analytics employs cookies that are stored to your computer in order to
                            facilitate an analysis of your use of the site. The information generated by these cookies, such as time, place and frequency of your
                            visits to our site, including your IP address, is transmitted to Google’s location in the US and stored there

                            Google analytics collects data by deploying analytics
                            cookies when the website is visited thereby providing insight to page viewership. The Google privacy statement
                            can be found <a rel="noopener noreferrer" href="https://www.google.com/intl/en/policies/privacy/" target="_blank">here</a>.
                            You can opt-out of Google Analytics
                             <a rel="noopener noreferrer"href="https://tools.google.com/dlpage/gaoptout" target="_blank"> here</a>.
                        </p>

                    </CardBody>
                </Card>

            </div>
        </Layout>
    )

}
export default PrivacyPolicy